import Vue from 'vue'
import JWT from 'jwt-decode'

export default {

  state: {
    /*
      isAuthenticated = true если пользователь залогинился на бекенд, false - если нет.
    */
    isAuthenticated: false,
    /*
      authUser - объект пользователя после авторизации
      {
        "uid":1,
        "login":"admin", - логин
        "password":"*",  - обычный пароль (не передаётся на frontend)
        "userEnabled":true, - пользователь включен
        "IsTimeLocked":false, - попало ли время логина во временной интервал
        "JWT":"..."
      }
    */
    authUser: {},
    /*
      apiBase - базовый адрес для API
    */
    apiBase: ''
  },
  mutations: {

    setAuthUser (state, payload) {
      if (this.$devmode) console.log('setAuthUser', payload)
      state.authUser = payload
      state.isAuthenticated = true
    },
    destroyAuthUser (state, payload) {
      if (this.$devmode) console.log('destroyAuthUser', payload)
      state.isAuthenticated = false
      state.authUser = {}
    },
    renewAuthJWT (state, payload) {
      if (this.$devmode) console.log('setAuthJWT', payload)
      state.authUser.JWT = payload.JWT
    },
    setApiBase (state, payload) {
      if (this.$devmode) console.log('setApiBase', payload)
      state.apiBase = payload
    }
  },
  actions: {
    /*
    sendNonAuthenticatedGet посылает неавторизированный GET запрос на api endpoint переданный через payload.endpoing, данные JSON в payload.sendData
    */
    sendNonAuthenticatedGet (state, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios.get(state.state.apiBase + payload.endpoint)
          .then(resp => {
            // state.commit('resetAlarm')
            resolve(resp)
          })
          .catch(err => {
            // state.commit('setAlarm', err)
            reject(err.response)
          })
      })
    },
    /*
    sendNonAuthenticatedPost посылает неавторизированный POST запрос на api endpoint переданный через payload.endpoing
    */
    sendNonAuthenticatedPost (state, payload) {
      return new Promise((resolve, reject) => {
        let axiosConfig = {
          headers: {
            'Content-Type': 'application/json'
          }
        }
        Vue.axios.post(state.state.apiBase + payload.endpoint, JSON.stringify(payload.sendData), axiosConfig)
          .then(resp => {
            // state.commit('resetAlarm')
            resolve(resp)
          })
          .catch(err => {
            // state.commit('setAlarm', err)
            reject(err.response)
          })
      })
    },
    /*
    sendAuthenticatedGet посылает авторизированный GET запрос на api endpoint переданный через payload.endpoing
    */
    sendAuthenticatedGet (state, payload) {
      return new Promise((resolve, reject) => {
        if (state.state.isAuthenticated === false) reject(Error('Not currently authenticated'))
        let axiosConfig = {
          headers: {
            'Authorization': 'Bearer ' + state.state.authUser.JWT
          }
        }
        Vue.axios.get(state.state.apiBase + payload.endpoint, axiosConfig)
          .then(resp => {
            // state.commit('resetAlarm')
            resolve(resp)
          })
          .catch(err => {
            // state.commit('setAlarm', err)
            reject(err.response)
          })
      })
    },
    /*
    sendAuthenticatedPost посылает авторизированный POST запрос на api endpoint переданный через payload.endpoing, данные JSON в payload.sendData
    */
    sendAuthenticatedPost (state, payload) {
      return new Promise((resolve, reject) => {
        if (state.state.isAuthenticated === false) reject(Error('Not currently authenticated'))
        let axiosConfig = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + state.state.authUser.JWT
          }
        }
        Vue.axios.post(state.state.apiBase + payload.endpoint, JSON.stringify(payload.sendData), axiosConfig)
          .then(resp => {
            // state.commit('resetAlarm')
            resolve(resp)
          })
          .catch(err => {
            // state.commit('setAlarm', err)
            reject(err.response)
          })
      })
    },
    /*
    sendAuthenticatedGet посылает авторизированный GET запрос на api endpoint переданный через payload.endpoing
    */
    sendAuthenticatedGetNoAlarm (state, payload) {
      return new Promise((resolve, reject) => {
        if (state.state.isAuthenticated === false) reject(Error('Not currently authenticated'))
        let axiosConfig = {
          headers: {
            'Authorization': 'Bearer ' + state.state.authUser.JWT
          }
        }
        Vue.axios.get(state.state.apiBase + payload.endpoint, axiosConfig)
          .then(resp => {
            resolve(resp)
          })
          .catch(err => {
            reject(err.response)
          })
      })
    },
    /*
  sendAuthenticatedPost посылает авторизированный POST запрос на api endpoint переданный через payload.endpoing, данные JSON в payload.sendData
  */
    sendAuthenticatedPostNoAlarm (state, payload) {
      return new Promise((resolve, reject) => {
        if (state.state.isAuthenticated === false) reject(Error('Not currently authenticated'))
        let axiosConfig = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + state.state.authUser.JWT
          }
        }
        Vue.axios.post(state.state.apiBase + payload.endpoint, JSON.stringify(payload.sendData), axiosConfig)
          .then(resp => {
            resolve(resp)
          })
          .catch(err => {
            reject(err.response)
          })
      })
    },
    /*
    Вызывается из таймера в main.js раз в 60 секунд.
    */
    async processJWTTimer (state) {
      if (state.state.isAuthenticated === false) return

      let x = JWT(state.state.authUser.JWT)
      let t = Math.floor(Date.now() / 1000)
      if (t < x.nbf) {
        console.log('processTimer: expired', t, x.nbf)
        state.commit('destroyAuthUser')
        return
      }
      if (t > x.exp) {
        console.log('processTimer: expired', t, x.exp)
        state.commit('destroyAuthUser')
        return
      }
      if (t > (x.exp - 300)) {
        console.log('processTimer: is about to expire, trying to renew', t, x.exp)
        let sendData = {
          action: 'renew'
        }
        state.dispatch('sendAuthenticatedPost', { endpoint: '/api/login/renew', sendData: sendData }).then(resp => { console.log('processJWTTimer', resp); state.state.authUser.jwt = resp.data.jwt }).catch(err => { console.log(err) })
      }
    },

    /*
      Вызывается при загрузке приложения
      Удаляет старый JWT, если он уже не актуален по времени
    */
    initAuth (state) {
      if (state.state.isAuthenticated) {
        let x = JWT(state.state.authUser.JWT)
        let t = Math.floor(Date.now() / 1000)
        if (t < x.nbf) {
          state.commit('destroyAuthUser')
          return
        }
        if (t > x.exp) {
          state.commit('destroyAuthUser')
        }
      }
    }
  },
  getters: {
    getApiBase: state => {
      return state.apiBase
    },
    getAuthUser: state => {
      return state.authUser
    },
    getAuthUserUID: state => {
      if (state.isAuthenticated === true) {
        return state.authUser.uid
      } else {
        return null
      }
    },
    getIsAuthenticated: state => {
      return state.isAuthenticated
    },
    getUserLogin: state => {
      if (typeof (state.authUser.login) === 'string') return state.authUser.login
      return ''
    },
    getUserJWT: state => {
      if (typeof (state.authUser.JWT) === 'string') return state.authUser.jwt
      return ''
    },
    getJTIfromJWT: state => {
      if (typeof (state.authUser.JWT) === 'string') {
        var base64Url = state.authUser.jwt.split('.')[1]
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) { return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2) }).join(''))
        var json = JSON.parse(jsonPayload)
        console.log('JWT:', json)
        return json.jti
      } else {
        return ''
      }
    },
    isAuthExpired: state => {
      if (state.isAuthenticated === false) {
        return false
      }
      let x = JWT(state.authUser.JWT)
      let t = Math.floor(Date.now() / 1000)
      if (t < x.nbf) {
        console.log('isAuthExpired: true', t, x.nbf)
        return true
      }
      if (t > x.exp) {
        console.log('isAuthenticated: true', t, x.exp)
        return true
      }
      return false
    }
  }
}
