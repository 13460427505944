import Vue from 'vue'

import axios from 'axios'
import VueAxios from 'vue-axios'
import i18n from './i18n'

import App from './App.vue'
import router from './router'
import store from './store'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.config.productionTip = false
const root = document.getElementById('app')
Vue.prototype.$devmode = false
Vue.prototype.$recaptchaKey = "6LfHxMwaAAAAACiEizrX8suMpLHGylRmrLdo7uT7"


if (process.env.NODE_ENV === 'development') {
  root.dataset.apibase = 'https://admin.sc-ai.org.ua/api/v1'
  Vue.prototype.$devmode = true
} else if (process.env.NODE_ENV === 'test') {
  root.dataset.apibase = 'https://admin.sc-ai.org.ua/api/v1'
  Vue.prototype.$devmode = true
} else {
  root.dataset.apibase = 'https://admin.sc-ai.org.ua/api/v1'
}

const ajax = axios.create({
  baseURL: root.dataset.apibase
})

Vue.use(VueAxios, ajax)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  created () {
    store.commit('setApiBase', root.dataset.apibase)

    //    store.dispatch('loadSettings')
    store.dispatch('initAuth')
  }


}).$mount('#app')
