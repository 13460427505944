import Vue from 'vue'
import Router from 'vue-router'
import store from './store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      name: 'login',
      component: () => import(/* webpackChunkName: "login" */ './views/Login.vue')
    },
    {
      path: '/changepassword',
      name: 'changepassword',
      component: () => import(/* webpackChunkName: "changepassword" */ './views/PasswordChange.vue')
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import(/* webpackChunkName: "logout" */ './views/Logout.vue')
    },
    {
      path: '/mazdaapp',
      name: 'mazdaapp',
      component: () => import(/* webpackChunkName: "about" */ './views/MazdaApp.vue')
    },
    {
      path: '*',
      name: '404',
      component: () => import(/* webpackChunkName: "login" */ './views/Login.vue')
    },
  ]
})

router.beforeEach((to, from, next) => {
  if (store.state.isAuthenticated === false) {
    if (to.fullPath === '/login') {
      next()
      return
    }
    if (to.fullPath === '/logout') {
      next()
      return
    }
    next('/login')
  }
  next()
  return
})

export default router
