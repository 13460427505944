<template>
  <div id="app">
    <b-container class="py-0">
      <b-navbar toggleable="lg" type="dark" variant="dark">
      <b-navbar-nav class="ml-auto">
        <b-nav-item href="/mazdaapp">Керування</b-nav-item>
        <b-nav-item href="/changepassword">Зміна паролю</b-nav-item>
        <b-nav-item href="/logout">Вихід</b-nav-item>
      </b-navbar-nav>
      </b-navbar>
    <router-view></router-view>
    </b-container>
  </div>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style>
.container {
  padding: 16px;
  /* max-width: 200px; */
  margin: 0 auto;
}
/* Full-width inputs */
input[type=text], input[type=password] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.cancelbtn {
  width: auto;
  padding: 10px 18px;
}
</style>
